#global-search-header,
#global-search-header-mob {
  .autocomplete-container {
    position: relative;
    z-index: 100;

    // padding-top: 15px;
    height: auto;
    width: 52px;
    cursor: pointer;
    background-color: transparent;

    // transition: width 500ms linear;

    .autocomplete-dropdown {
      padding: initial;
      z-index: 1000;
      opacity: 0;
      background: initial;
      border: initial;
      box-shadow: initial;

      // transition: opacity 300ms linear;

      .recent-searches {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding-left: initial;
      }

      h6 {
        color: #e6472a;
        font-size: 16px;
      }

      li {
        list-style: none;
        padding: 10px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        border: initial;

        &:hover {
          background: initial;
        }

        a {
          color: #004071;
          transition: all 75ms linear;

          em {
            font-style: normal;
          }

          span {
            span {
              font-weight: 700;
            }
          }

          &:hover {
            color: #e6472a;
            text-decoration: none;

            // font-weight: 700;

            // em {
            //  color: #004071;
            // }
          }
        }

        b {
          font-weight: 900;
        }

        .fa-search {
          font-size: 15px;
          width: 30px;
          height: 30px;
          color: #e6472a;
          background: #f0f5f8;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .recent-search-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 20px;
          cursor: pointer;
          vertical-align: sub;
          margin-right: 10px;
        }

        &__remove {
          margin-right: initial !important;
          align-self: flex-start;
          margin-top: 4px;
        }
      }
    }

    .autocomplete-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #e6472a;
      border-radius: 40px;

      // padding: 6px 20px;
      background: #fff;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .open-global-search-btn {
        background: initial;
        border: initial;
        padding: 0;
        width: 20px;
        height: 20px;

        .fa-search {
          color: #c2c9d4;
          font-size: 18px;
          width: 100%;
          height: 100%;
        }
      }

      .close-global-search-btn {
        width: 24px;
        height: 24px;
        background: #e6472a;
        border: initial;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .fa-times {
          color: white;
          font-size: 18px;
          flex: none;
        }
      }

      .autocomplete-input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        line-height: normal;
        border: initial;
        border-radius: initial;
        margin-top: initial;
        caret-color: #e6472a;

        &::placeholder {
          color: #c2c9d4;
        }

        &:focus {
          border-color: initial;
          box-shadow: initial;
        }
      }
    }

    &.open-list {
      background-color: #dbecf9;
      border-radius: 10px;
      padding: 15px 10px;
      height: auto;

      .autocomplete-dropdown {
        opacity: 1;
      }
    }

    .loader-container {
      min-height: 150px;
    }

    .loader {
      width: 20px;
      height: 20px;
      border: 2px solid #004071;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    .quick-links {
      margin: 16px 10px 0;

      &__title {
        font-size: 16px;
        font-weight: 700;
        font-family: Caros, sans-serif;
        margin-bottom: 10px;
        color: #004071;
      }

      &__list {
        padding-left: initial;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        list-style-type: none;
      }

      &__item {
        margin-bottom: 10px;
      }

      &__link {
        font-size: 16px;
        color: #004071;
        transition: all 100ms linear;
        position: relative;

        &:hover {
          text-decoration: none;
          color: #e6472a;
        }
      }
    }
  }

  .suggested-text {
    cursor: pointer;
  }
}

.template-for-organization-page {
  #global-search-header,
  #global-search-header-mob {
    .autocomplete-container.open-list {
      border: 1px solid #dbecf9;
      background: #fff;
    }
  }
}
